import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import registerServiceWorker from "./registerServiceWorker";
import { createStore, applyMiddleware, compose } from "redux";
import logger from "redux-logger";
import rootReducer from "./store/reducers/rootReducer";
import thunk from "redux-thunk";
import "./index.css";
import App from "./App";
import { auth } from "./config/firebaseConfig";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

// Create an HTTP link to your GraphQL server
const httpLink = createHttpLink({
  uri:
    process.env.NODE_ENV === "production"
      ? "https://friends-league-server.onrender.com/graphql"
      : "http://localhost:4000/graphql",
});

// Create an auth link to include the Firebase ID token in headers
const authLink = setContext(async (_, { headers }) => {
  const user = auth.currentUser;
  const token = user ? await user.getIdToken() : "";
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// Initialize Apollo Client
export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

// Initialize Redux store
const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk.withExtraArgument({ auth }), logger)
    // Removed reduxFirestore and other Firebase middleware
  )
);

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <App />
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);

registerServiceWorker();
