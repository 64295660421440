import { jsonToGraphQLQuery } from "json-to-graphql-query";
import { getUserData, mapRatings, graphqlRequest } from "./ActionUtils";

const graphqlURL =
  process.env.NODE_ENV === "production"
    ? "https://friends-league-server.onrender.com/graphql"
    : "http://localhost:4000/graphql";

export const createLeague = (
  leagueName,
  leagueDescription,
  leagueImageURL,
  user
) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_START" });

    const addLeagueMutation = {
      mutation: {
        addLeague: {
          __args: {
            leagueName,
            leagueDescription,
            leagueImageURL,
            adminId: user.id,
            users: [user.id],
          },
          id: true,
          leagueName: true,
          leagueDescription: true,
          leagueImageURL: true,
          adminId: true,
          users: {
            id: true,
            displayName: true,
            displayPictureURL: true,
            email: true,
          },
        },
      },
    };

    try {
      const { data } = await graphqlRequest(addLeagueMutation);
      const newLeague = data.addLeague;

      const addRatingMutation = {
        mutation: {
          addRating: {
            __args: {
              leagueId: newLeague.id,
              userId: user.id,
              elo: 1000,
            },
            id: true,
          },
        },
      };

      await graphqlRequest(addRatingMutation);

      dispatch({ type: "CREATE_LEAGUE", league: newLeague });
      dispatch({ type: "NEW_LEAGUE_CREATED" });
      return newLeague;
    } catch (err) {
      console.error(err);
      dispatch({ type: "CREATE_LEAGUE_ERROR", err });
      throw err;
    }
  };
};

export const updateLeagues = (leagues) => ({
  type: "UPDATE_LEAGUES",
  leagues,
});

export const updateLeague = (
  leagueName,
  leagueDescription,
  leagueImageURL,
  leagueId,
  userId
) => {
  return async (dispatch, getState) => {
    dispatch({ type: "LOADING_START" });

    const updateLeagueMutation = {
      mutation: {
        updateLeague: {
          __args: {
            id: leagueId,
            leagueName,
            leagueDescription,
            leagueImageURL,
          },
          id: true,
          leagueName: true,
          leagueDescription: true,
          leagueImageURL: true,
          adminId: true,
          users: {
            id: true,
            displayName: true,
            displayPictureURL: true,
            email: true,
          },
        },
      },
    };

    try {
      const { data } = await graphqlRequest(updateLeagueMutation);
      const updatedLeague = data.updateLeague;

      console.log("Updated league from server:", updatedLeague);

      // Update the leagues array
      const currentLeagues = getState().leagues.leagues;
      const updatedLeagues = currentLeagues.map((league) =>
        league.id === leagueId ? updatedLeague : league
      );

      console.log("Updated leagues array:", updatedLeagues);

      dispatch({
        type: "UPDATE_LEAGUE",
        leagues: updatedLeagues,
        selectedLeague: updatedLeague,
      });

      dispatch({ type: "LOADING_COMPLETE" });

      // Return the updated league
      return updatedLeague;
    } catch (err) {
      console.error("Error updating league:", err);
      dispatch({ type: "UPDATE_LEAGUE_ERROR", error: err });
      throw err;
    }
  };
};

export const joinLeague = (leagueId, user) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_START" });
    const addUserToLeagueMutation = {
      mutation: {
        addUserToLeague: {
          __args: {
            leagueId,
            userId: user.id,
          },
          id: true,
        },
      },
    };

    try {
      await graphqlRequest(addUserToLeagueMutation, "mutation");

      const addRatingMutation = {
        mutation: {
          addRating: {
            __args: {
              leagueId,
              userId: user.id,
              elo: 1000,
            },
            id: true,
          },
        },
      };

      await graphqlRequest(addRatingMutation, "mutation");

      const getLeagueQuery = {
        query: {
          getLeague: {
            __args: {
              id: leagueId,
            },
            id: true,
            leagueDescription: true,
            leagueImageURL: true,
            leagueName: true,
            adminId: true,
            users: {
              id: true,
              displayName: true,
              displayPictureURL: true,
              ratings: {
                id: true,
                leagueId: true,
                userId: true,
                elo: true,
              },
            },
          },
        },
      };

      const { data } = await graphqlRequest(getLeagueQuery);
      const joinedLeague = data.getLeague;
      const newUsers = joinedLeague.users.map((user) => {
        const rating = user.ratings.find(
          (rating) => rating.leagueId === leagueId
        );
        user.elo = rating ? rating.elo : 1000;
        return user;
      });
      joinedLeague.users = newUsers;

      dispatch({ type: "JOIN_LEAGUE", league: joinedLeague });
      dispatch({ type: "NEW_LEAGUE_JOINED" });

      return joinedLeague;
    } catch (err) {
      dispatch({ type: "JOIN_LEAGUE_ERROR", err });
      throw err;
    }
  };
};

export const selectLeague = (leagueId) => {
  return async (dispatch, getState) => {
    const { leagues } = getState();

    // Check if leagues is an array and not empty
    if (Array.isArray(leagues) && leagues.length > 0) {
      const selectedLeague = leagues.find((league) => league.id === leagueId);
      if (selectedLeague) {
        dispatch({ type: "SELECT_LEAGUE_SUCCESS", league: selectedLeague });
      } else {
        // If league is not found in the state, fetch it from the server
        await fetchAndSelectLeague(dispatch, leagueId);
      }
    } else {
      // If leagues is not an array or is empty, fetch the league from the server
      await fetchAndSelectLeague(dispatch, leagueId);
    }
  };
};

const fetchAndSelectLeague = async (dispatch, leagueId) => {
  try {
    const getLeagueQuery = {
      query: {
        getLeague: {
          __args: { id: leagueId },
          id: true,
          leagueName: true,
          leagueDescription: true,
          leagueImageURL: true,
          adminId: true,
          users: {
            id: true,
            displayName: true,
            displayPictureURL: true,
            email: true,
            ratings: {
              id: true,
              leagueId: true,
              userId: true,
              elo: true,
            },
          },
        },
      },
    };

    const { data } = await graphqlRequest(getLeagueQuery);

    if (data && data.getLeague) {
      const league = data.getLeague;
      // Process ratings if needed
      league.users = league.users.map((user) => {
        const rating = user.ratings.find((r) => r.leagueId === league.id);
        return { ...user, elo: rating ? rating.elo : 1000 };
      });
      dispatch({ type: "SELECT_LEAGUE_SUCCESS", league: league });
    } else {
      throw new Error("League not found");
    }
  } catch (error) {
    console.error("Error fetching league:", error);
    dispatch({ type: "SELECT_LEAGUE_ERROR", error });
  }
};

export const addResult = ({
  userOneId,
  userOneScore,
  userTwoId,
  userTwoScore,
  matchResult,
  leagueId,
}) => {
  return async (dispatch, getState) => {
    dispatch({ type: "LOADING_START" });

    const addResultMutation = {
      mutation: {
        addResult: {
          __args: {
            leagueId,
            userOneId,
            userOneScore,
            userTwoId,
            userTwoScore,
            matchResult,
          },
          id: true,
        },
      },
    };

    try {
      console.log(
        "Sending mutation:",
        JSON.stringify(addResultMutation, null, 2)
      );
      const { data } = await graphqlRequest(addResultMutation);
      console.log("Received response:", data);

      // After adding the result, fetch the updated league data
      await dispatch(selectLeague(leagueId));
    } catch (err) {
      console.error("Error during mutation:", err);
      dispatch({ type: "UPDATE_LEAGUE_ERROR", err });
    } finally {
      dispatch({ type: "LOADING_COMPLETE" });
    }
  };
};
