// src/store/actions/backendIntegration.js

import { jsonToGraphQLQuery } from "json-to-graphql-query";
import { auth } from "../../config/firebaseConfig";

const graphqlURL =
  process.env.NODE_ENV === "production"
    ? "https://friends-league-server.onrender.com/graphql"
    : "http://localhost:4000/graphql";

export async function addUserToBackend(
  uid,
  displayName,
  displayPictureURL,
  email
) {
  console.log("addUserToBackend called with:", {
    uid,
    displayName,
    displayPictureURL,
    email,
  });
  // Get Firebase ID token
  const token = await auth.currentUser.getIdToken();

  const mutation = {
    mutation: {
      addUser: {
        __args: {
          displayName: displayName,
          displayPictureURL: displayPictureURL,
          email: email,
          registrationId: uid,
        },
        id: true,
        displayName: true,
        displayPictureURL: true,
        email: true,
      },
    },
  };

  const graphqlMutation = jsonToGraphQLQuery(mutation, { pretty: true });

  try {
    const response = await fetch(graphqlURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ query: graphqlMutation }),
    });

    const { data } = await response.json();

    if (!data || !data.addUser) {
      throw new Error("Failed to add user");
    }

    return data.addUser;
  } catch (error) {
    console.error("Error adding user to backend:", error);
    throw error;
  }
}
